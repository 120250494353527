<template>
  <div id="app">
    <div class="sidebarContainer" v-bind:class="{ shrink: isShrinkView }">
      <button class="sidebarViewButton" type="button"
        v-bind:aria-label='isShrinkView ? "Expand Sidebar" : "Shrink Sidebar"'
        v-bind:title='isShrinkView ? "Expand" : "Shrink"' @click=handleSidebarView()>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-chevron-left">
          <polyline points="15 18 9 12 15 6" />
        </svg>
      </button>
      <div class="sidebarWrapper">
        <div class="sidebarThemeContainer">
          <label labelFor="theme-toggle" class="sidebarThemeLabel" v-bind:class="{ switched: isDarkMode }">
            <input class="sidebarThemeInput" type="checkbox" id="theme-toggle" @change=handleThemeChange() />
            <div class="sidebarThemeType light">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="sidebarListIcon">
                <circle cx="12" cy="12" r="5" />
                <path
                  d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
              </svg>
              <span class="sidebarThemeInputText">Light</span>
            </div>
            <div class="sidebarThemeType dark">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="sidebarListIcon">
                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
              </svg>
              <span class="sidebarThemeInputText">Dark</span>
            </div>
          </label>
        </div>
        <ul class="sidebarList">
          <li class="sidebarListItem" v-bind:class="{ active: activeLink === 1 }">
            <router-link to="/find-company" tag="a" v-on:click.native="activeLink = 1" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 32 32"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sidebarListIcon">
                <path
                  d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
              </svg>
              <span class="sidebarListItemText">Поиск</span>
            </router-link>
          </li>
          <li class="sidebarListItem" v-bind:class="{ active: activeLink === 2 }">
            <router-link to="/create-company" tag="a" v-on:click.native="activeLink = 2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sidebarListIcon">
                <path
                  d="M31.562 25.905l-9.423-9.423c-0.583-0.583-1.538-0.583-2.121 0l-0.707 0.707-5.75-5.75 9.439-9.439h-10l-4.439 4.439-0.439-0.439h-2.121v2.121l0.439 0.439-6.439 6.439 5 5 6.439-6.439 5.75 5.75-0.707 0.707c-0.583 0.583-0.583 1.538 0 2.121l9.423 9.423c0.583 0.583 1.538 0.583 2.121 0l3.535-3.535c0.583-0.583 0.583-1.538 0-2.121z" />
              </svg>
              <span class="sidebarListItemText">Создать</span>
            </router-link>
          </li>
          <li class="sidebarListItem" v-bind:class="{ active: activeLink === 3 }">
            <router-link to="/payments" tag="a" v-on:click.native="activeLink = 3" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sidebarListIcon">
                <path
                  d="M29 4h-26c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-18c0-1.65-1.35-3-3-3zM3 6h26c0.542 0 1 0.458 1 1v3h-28v-3c0-0.542 0.458-1 1-1zM29 26h-26c-0.542 0-1-0.458-1-1v-9h28v9c0 0.542-0.458 1-1 1zM4 20h2v4h-2zM8 20h2v4h-2zM12 20h2v4h-2z" />
              </svg>
              <span class="sidebarListItemText">Платежи</span>
            </router-link>
          </li>
          <li class="sidebarListItem" v-bind:class="{ active: activeLink === 4 }">
            <router-link to="/cards" tag="a" v-on:click.native="activeLink = 4" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sidebarListIcon">
                <path
                  d="M28.576,9.516V6.342H0v17.867h5.146v1.449H32V9.516H28.576z M26.502,8.396v7.094H2.123V8.396H26.502z M2.123,19.686h24.379V22.1H2.123V19.686z" />
              </svg>
              <span class="sidebarListItemText">Карты</span>
            </router-link>
          </li>
          <li class="sidebarListItem" v-bind:class="{ active: activeLink === 5 }">
            <router-link to="/stage-configuration" tag="a" v-on:click.native="activeLink = 5">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sidebarListIcon">
                <circle cx="12" cy="12" r="3" />
                <path
                  d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
              </svg>
              <span class="sidebarListItemText">Настройки</span>
            </router-link>
          </li>
        </ul>
        <router-link to="/" tag="a" class="sidebarProfileSection" v-on:click.native="activeLink = undefined">
          <img src="https://assets.codepen.io/3306515/i-know.jpg" width="40" height="40" alt="QA Tools" />
          <span class="sidebarListItemText">QA Tools</span>
          </router-link>
      </div>
    </div>
    <div class="workspaceContainer">
      <router-view></router-view>
    </div>
    <div class="historyContainer">
      <HistoryModule></HistoryModule>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
Vue.component('HistoryModule', require('@/components/HistoryModule.vue').default);

export default {
  name: "app",
  data() {
    return {
      isShrinkView: this.$store.getters.VIEW,
      isDarkMode: this.$store.getters.DARK_MODE,
      activeLink: undefined
    }
  },
  created: function () {
    if (this.isDarkMode) {
      document.body.classList.toggle("dark")
    }
  },
  methods: {
    handleSidebarView: function () {
      this.isShrinkView = !this.isShrinkView
      this.$store.commit('setIsShrinkView', this.isShrinkView)
    },
    handleThemeChange: function () {
      this.isDarkMode = !this.isDarkMode
      this.$store.commit('setIsDarkMode', this.isDarkMode)
      document.body.classList.toggle("dark")
    }
  }
}
</script>

<style lang="scss">@import "./styles/root";</style>
