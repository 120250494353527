import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isShrinkView: false,
    isDarkMode: false,
    historyList: []
  },
  getters: {
    VIEW: state => {
      return state.isShrinkView;
    },
    DARK_MODE: state => {
      return state.isDarkMode;
    },
    HISTORY_LIST: state => {
      return state.historyList;
    }
  },
  mutations: {
    setIsShrinkView: (state, payload) => {
      state.isShrinkView = payload;
    },
    setIsDarkMode: (state, payload) => {
      state.isDarkMode = payload;
    },
    addHistory: (state, payload) => {
      state.historyList.push(payload);
    }
  },
  actions: {
  },
  modules: {
  }
});
