import Vue from 'vue';
import VueRouter from 'vue-router';
import Payments from "@/components/home/Payments";
import CardOperation from "@/components/home/CardOperation";
import Cards from "@/components/home/Cards";
import CreateCompany from "@/components/home/CreateCompany";
import CreateFlow from "@/components/home/CreateFlow";
import FindCompany from "@/components/home/FindCompany";
import FlowList from "@/components/home/FlowList";
import StageConfiguration from "@/components/home/StageConfiguration";

Vue.use(VueRouter);

const routes = [
    {
        path: '/payments',
        name: 'Payments',
        component: Payments
    },
    {
        path: '/card-operation',
        name: 'CardOperation',
        component: CardOperation
    },
    {
        path: '/cards',
        name: 'Cards',
        component: Cards
    },
    {
        path: '/create-company',
        name: 'CreateCompany',
        component: CreateCompany
    },
    {
        path: '/create-flow',
        name: 'CreateFlow',
        component: CreateFlow
    },
    {
        path: '/find-company',
        name: 'FindCompany',
        component: FindCompany
    },
    {
        path: '/flow-list',
        name: 'FlowList',
        component: FlowList
    },
    {
        path: '/stage-configuration',
        name: 'RemoveCompany',
        component: StageConfiguration
    }
];

const router = new VueRouter({
    routes
});

export default router;
