<template>
  <div class="workspaceWrapper">
    <div class="workspaceThemeContainer">
      <div id="payments">
        <div class="form">
          <b-form class="mb-2">
            <h3>Платежи</h3>
            <b-row>
              <b-col>
                <div>
                  <div class="mt-2">
                    <b-form-input id="company-id" type="text" :state="maskUUID"
                      formatter="00000000-0000-0000-0000-000000000000" autocomplete="off" placeholder="id компании"
                      v-model="companyId" />
                    <b-form-invalid-feedback id="company-id-feedback">
                      Заполни по маске UUID
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2"></div>
                  <div v-show="paymentType === 'addMoney'">
                    <b-form-input id="bank-account-number" type="text" :state="maskBankAccountNumber"
                      formatter="00000 00000 00000 00000" autocomplete="off" placeholder="Номер счёта"
                      v-model="bankAccount" />
                    <b-form-invalid-feedback id="bank-account-number-feedback">
                      Заполни по маске номера счета
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2"></div>
                  <div class="mt-2">
                    <b-form-input id="amount" v-model="amount" :state="maskAmountWithDot" type="text" formatter="0*.00"
                      autocomplete="off" placeholder="Сумма зачисления" />
                    <b-form-invalid-feedback id="amount-feedback">
                      Заполни по маске 0*.00
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2"></div>
                  <div v-show="paymentsWithDetails.includes(paymentType)">
                    <b-form-input id="counterparty-inn" type="text" :state="maskInn" formatter="000000000000"
                      autocomplete="off" placeholder="ИНН контрагента (необязательное)" v-model="counterpartyInn" />
                  </div>
                  <div class="mt-2"></div>
                  <div v-show="paymentsWithDetails.includes(paymentType)">
                    <b-form-input id="counterparty-name" type="text" :state="maskName" autocomplete="off"
                      placeholder="Наименование контрагента (необязательное)" v-model="counterpartyName" />
                  </div>
                  <div class="mt-2"></div>
                  <div v-show="paymentType != 'addMoney'">
                    <b-form-input id="purpose" type="text" :state="maskName" autocomplete="off"
                      placeholder="Назначение платежа (необязательное)" v-model="purpose" />
                  </div>
                  <br>
                  <b-row>
                    <b-col md="4">
                      <b-button v-on:click="submit" :disabled="isProcessing" type="submit"
                        variant="primary">Выполнить</b-button>
                    </b-col>
                    <b-col>
                      <b-button v-on:click="clean" type="clean" variant="primary">Очистить</b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="mb-2" lg="5">
                <div class="mt-2">
                  <b-form-group label="Направление платежа: " v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="paymentDirection" :aria-describedby="ariaDescribedby" name="payment-type"
                      value='incoming' v-on:change="changePaymentDirection">Входящий платеж</b-form-radio>
                    <b-form-radio v-model="paymentDirection" :aria-describedby="ariaDescribedby" name="payment-type"
                      value='outgoing' v-on:change="changePaymentDirection">Исходящий платеж</b-form-radio>
                  </b-form-group>
                </div>
                <br>
                <div class="mt-2">
                  <h7>Тип платежа: </h7>
                  <b-form-select v-model="paymentType"
                    :options="paymentDirection === 'incoming' ? incomingPayments : outgoingPayments"></b-form-select>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-form class="mb-2">
            <h3>Статус</h3>
            <div>
              <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged">
                {{ alertMessage }}
              </b-alert>
            </div>
            <div>
              <b-alert variant="success" :show="successfullyRequested" dismissible>
                Запрос выполнен успешно.
                <hr />
              </b-alert>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Companies } from "@/api/http-common";
import { Internal } from "@/api/http-common";

export default {
  name: "AddMoney",
  data() {
    return {
      paymentDirection: 'incoming',
      paymentType: 'addMoney',
      incomingPayments: [
        { value: 'addMoney', text: 'Пополнить счет' },
        { value: 'paymentFromLegalEntity', text: 'Входящий платеж от Юрлица' },
        { value: 'paymentFromIndividualEntrepreneur', text: 'Входящий платеж от ИП' },
        { value: 'paymentFromIndividual', text: 'Входящий платеж от Физлица' },
        { value: 'paymentFromTaxInspection', text: 'Входящий платеж от налоговой' },
        { value: 'paymentFromOwnCompany', text: 'Входящий платеж от себя' },
        { value: 'paymentWithValidation', text: 'Платеж с проверкой в комплаенсе' }
      ],
      outgoingPayments: [
        { value: 'paymentToLegalEntity', text: 'Исходящий платеж на Юрлицо' },
        { value: 'paymentToIndividualEntrepreneur', text: 'Исходящий платеж на ИП' },
        { value: 'paymentToIndividual', text: 'Исходящий платеж на Физлицо' },
        { value: 'paymentToTaxInspection', text: 'Исходящий платеж в налоговую' },
        { value: 'paymentToOwnCompany', text: 'Исходящий платеж себе' },
      ],
      paymentsWithDetails: [
        'paymentFromLegalEntity',
        'paymentFromIndividualEntrepreneur',
        'paymentWithValidation',
        'paymentToLegalEntity',
        'paymentToIndividualEntrepreneur'
      ],
      companyId: "",
      bankAccount: "",
      amount: "1000",
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: "",
      successfullyRequested: false,
      isProcessing: false,
      counterpartyInn: "",
      counterpartyName: "",
      purpose: "",
    };
  },
  methods: {
    submit: function () {
      let body = {}
      let path = ""

      if (this.$data.companyId === "" || this.$data.companyId == null) {
        this.$data.alertMessage = 'Пожалуйста, заполни поле "companyId"';
        this.showAlert();
        return;
      }

      if (this.$data.amount === "" || this.$data.amount == null) {
        this.$data.alertMessage = 'Пожалуйста, заполни поле "Amount"';
        this.showAlert();
        return;
      }

      switch (this.$data.paymentType) {
        case 'addMoney': {
          if (this.$data.bankAccount === "" || this.$data.bankAccount == null) {
            this.$data.alertMessage = 'Пожалуйста, заполни поле "Bank account"';
            this.showAlert();
            return;
          }
          this.isProcessing = true;
          body = {
            bankAccountNumber: this.$data.bankAccount,
            amount: this.$data.amount.replace(",", "."),
          };
          Companies.post(`/${this.$data.companyId}/addmoney`, body)
            .then(
              (response) => {
                console.log(response);
                this.successAlert();
              },
              (error) => {
                console.log(error);
                this.$data.alertMessage = error.response.data.error.errorMessage;
                this.showAlert();
              }
            )
            .catch((e) => {
              console.log(e);
            });
          return;
        }
        case 'paymentFromLegalEntity': {
          path = '/payment/incoming/create/fromLegalEntity';
          break;
        }
        case 'paymentFromIndividualEntrepreneur': {
          path = '/payment/incoming/create/fromIndividualEntrepreneur';
          break;
        }
        case 'paymentFromIndividual': {
          path = '/payment/incoming/create/fromIndividual';
          break;
        }
        case 'paymentFromTaxInspection': {
          path = '/payment/incoming/create/fromTaxInspection';
          break;
        }
        case 'paymentFromOwnCompany': {
          path = '/payment/incoming/create/fromOwnCompany';
          break;
        }
        case 'paymentWithValidation': {
          path = '/payment/incoming/create/complianceValidation';
          break;
        }
        case 'paymentToLegalEntity': {
          path = '/payment/outgoing/create/toLegalEntity';
          break;
        }
        case 'paymentToIndividualEntrepreneur': {
          path = '/payment/outgoing/create/toIndividualEntrepreneur';
          break;
        }
        case 'paymentToIndividual': {
          path = '/payment/outgoing/create/toIndividual';
          break;
        }
        case 'paymentToTaxInspection': {
          path = '/payment/outgoing/create/toTaxInspection';
          break;
        }
        case 'paymentToOwnCompany': {
          path = '/payment/outgoing/create/toOwnCompany';
          break;
        }
      }

      this.isProcessing = true;
      body = {
        amount: this.$data.amount.replace(",", "."),
        companyId: this.$data.companyId,
        counterpartyInn: this.$data.counterpartyInn,
        counterpartyName: this.$data.counterpartyName,
        purpose: this.$data.purpose,
      };
      Internal.post(path, body)
        .then(
          (response) => {
            console.log(response);
            this.successAlert();
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e);
        });
    },

    changePaymentDirection: function () {
      if (this.$data.paymentDirection === 'incoming') {
        this.paymentType = 'addMoney';
      } else {
        this.paymentType = 'paymentToLegalEntity';
      }
    },

    clean: function () {
      this.companyId = "";
      this.bankAccount = "";
      this.amount = "";
      this.counterpartyInn = "";
      this.counterpartyName = "";
      this.purpose = "";
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    showAlert() {
      this.isProcessing = false;
      this.dismissCountDown = this.dismissSecs;
    },

    successAlert() {
      this.successfullyRequested = true;
      this.isProcessing = false;
    },
  },
  computed: {
    maskUUID() {
      const maskUUID =
        /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/i;
      return this.companyId.match(maskUUID) ? true : false;
    },
    maskBankAccountNumber() {
      const maskBankAccountNumber = /[0-9]{20}/i;
      return this.bankAccount.match(maskBankAccountNumber) ? true : false;
    },
    maskAmountWithDot() {
      const maskAmountWithDot = /\d{1,}/i;
      return this.amount.match(maskAmountWithDot) ? true : false;
    },
  },
};
</script>

<style></style>