<template>
    <ul>
        <li v-for="detail in details" v-bind:key="detail">
            {{ detail.name }}
            <br>
            Номер - {{ detail.phone }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "history-detail-company",
    props: {
        details: {
            type: Array,
            required: true
        }
    }
}
</script>

<style></style>