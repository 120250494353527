<template>
  <div class="historyWrapper">
    <div class="historyThemeContainer">
      <div id="history-module">
        <b-form class="mb-2">
          <h4>История</h4>
          <div>
            <div class="mt-2"></div>
            <history-detail-company :details="details"></history-detail-company>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryDetailCompany from './HistoryDetailCompany.vue';

export default {
  name: "history-module",
  components: {
    HistoryDetailCompany
  },
  data() {
    return {
      details: this.$store.getters.HISTORY_LIST
    }
  }
}
</script>

<style></style>