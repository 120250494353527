<template>
    <div class="workspaceWrapper">
        <div class="workspaceThemeContainer">
            <b-tabs active-nav-item-class="font-weight-bold fs-5" content-class="mt-3" justified>
                <b-tab title="Запрос карты для активации" active @click="successfullyRequested = false; alertMessage = ''; dismissCountDown = 0">
                    <div id="cards">
                        <div class="form">
                            <b-form class="mb-2">
                                <div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <h7>Тип карты: </h7>
                                        <b-form-select v-model="cardType" :options="cardTypeOptions"
                                            v-on:change="changeCardType" size="lg"></b-form-select>
                                    </div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <span>Дата начала действия: {{ cardDate }}</span>
                                    </div>
                                </div>
                                <br>
                                <b-button v-on:click="getCard" :disabled="getCardDisabled" type="submit"
                                    variant="primary">Получить карту для активации</b-button>
                            </b-form>
                            <b-form class="mb-2">
                                <h3>Статус</h3>
                                <div>
                                    <b-alert :show="dismissCountDown" dismissible variant="danger"
                                        @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
                                        {{ alertMessage }}
                                    </b-alert>
                                </div>
                                <div>
                                    <b-alert variant="success" :show="successfullyRequested" dismissible
                                        @dismissed="successfullyRequested = false">
                                        Запрос выполнен успешно.
                                        <hr />
                                        <span id="pan">PAN: {{ pan }}</span>
                                        <div class="mt-2"></div>
                                        <span id="expDate">EXP_DATE: {{ expDate }}</span>
                                        <div class="mt-2"></div>
                                        <span id="embName">EMB_NAME: {{ embName }}</span>
                                        <div class="mt-2"></div>
                                        <span id="dateBegin">DATE_BEGIN: {{ dateBegin }}</span>
                                        <div class="mt-2"></div>
                                        <span id="ipContract">IP_CONTRACT: {{ ipContract }}</span>
                                    </b-alert>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Операции по карте" @click="successfullyRequested = false; alertMessage = ''; dismissCountDown = 0">
                    <div id="cardOperations">
                        <div class="form">
                            <b-form class="mb-2">
                                <div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <h7>Тип операции: </h7>
                                        <b-form-select v-model="operationType" :options="operationTypeOptions"
                                            size="lg"></b-form-select>
                                    </div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <b-form-input id="company-id" v-model="companyId" type="text" :state="maskUUID"
                                            formatter="00000000-0000-0000-0000-000000000000" autocomplete="off"
                                            placeholder="id компании с активной картой" />
                                        <b-form-invalid-feedback id="company-id-feedback">
                                            Заполни по маске UUID
                                        </b-form-invalid-feedback>
                                    </div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <b-form-input id="amount" v-model="amount" type="text"
                                            :state="maskAmountWithDot" formatter="0*.00" autocomplete="off"
                                            placeholder="Сумма операции" />
                                        <b-form-invalid-feedback id="amount-feedback">
                                            Заполни по маске 0*.00
                                        </b-form-invalid-feedback>
                                    </div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <b-form-input id="mcc-code" v-model="mccCode" type="text" :state="mccCodeState"
                                            formatter="0000" autocomplete="off" placeholder="MCC-код (необязательно)" />
                                    </div>
                                    <div class="mt-2"></div>
                                    <div class="mt-2">
                                        <b-form-input id="merchant-name" v-model="merchantName" type="text"
                                            :state="maskName" autocomplete="off"
                                            placeholder="Название мерчанта (необязательно)" />
                                    </div>
                                </div>
                                <br>
                                <b-button v-on:click="createOperation" :disabled="isProcessing" type="submit"
                                    variant="primary">Создать операцию</b-button>
                            </b-form>
                            <b-form class="mb-2">
                                <h3>Статус</h3>
                                <div>
                                    <b-alert :show="dismissCountDown" dismissible variant="danger"
                                        @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
                                        {{ alertMessage }}
                                    </b-alert>
                                </div>
                                <div>
                                    <b-alert variant="success" :show="successfullyRequested" dismissible
                                        @dismissed="successfullyRequested = false">
                                        Запрос выполнен успешно.
                                    </b-alert>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { Companies } from "@/api/http-common";

export default {
    name: "Cards",
    data() {
        return {
            cardDate: '',
            getCardDisabled: true,
            successfullyRequested: false,
            isProcessing: false,
            alertMessage: "",
            dismissSecs: 10,
            dismissCountDown: 0,
            cardTypeOptions: [
                { text: 'Выберите тип карты', value: null },
                { text: 'MIR Privilege Business Blanc', value: { cardProduct: 'MIR Privilege Business Blanc', dateBegin: '2024-01-09' } },
                { text: 'Visa Business Blanc Розан', value: { cardProduct: 'Visa Business Blanc Розан', dateBegin: '2024-01-09' } },
                { text: 'MIR Digital Privilege Business Blanc', value: { cardProduct: 'MIR Digital Privilege Business Blanc', dateBegin: '2024-05-20' } }
            ],
            cardType: null,
            pan: "",
            expDate: "",
            embName: "",
            dateBegin: "",
            ipContract: "",
            companyId: "",
            amount: "",
            merchantName: "",
            mccCode: "",
            operationTypeOptions: [
                { text: 'Выберите тип операции', value: null },
                {
                    label: 'Исходящие операции',
                    options: [
                        { text: 'Снятие наличных в банкомате (700)', value: { code: 700 } },
                        { text: 'Оплата картой в интернете (680)', value: { code: 680 } },
                        { text: 'Оплата картой по терминалу (774)', value: { code: 774 } },
                        { text: 'Платёж с карты на карту (508)', value: { code: 508 } },
                        { text: 'Оплата картой epos до авторизации (677)', value: { code: 677 } },
                        { text: 'Оплата картой pos до авторизации (736)', value: { code: 736 } },
                        { text: 'Перевод с карты на карту (781)', value: { code: 781 } },
                        { text: 'Терминал для снятия наличных (777)', value: { code: 777 } },
                        { text: 'Снятие наличных при покупке снятия наличных (653)', value: { code: 653 } },
                    ]
                },
                {
                    label: 'Входящие операции',
                    options: [
                        { text: 'Поступление наличных (618)', value: { code: 618 } },
                        { text: 'Поступление на карту с карты (785)', value: { code: 785 } },
                        { text: 'Транзакция по выплате валового дохода на карту (698)', value: { code: 698 } },
                        { text: 'Возврат операции по карте (775)', value: { code: 775 } },
                        { text: 'Внесение средств через ATM PVN (712)', value: { code: 712 } }
                    ]
                },
            ],
            operationType: null,
        };
    },
    methods: {
        changeCardType: function () {
            if (this.$data.cardType === null) {
                this.cardDate = '';
                this.getCardDisabled = true;
            } else {
                this.cardDate = this.$data.cardType.dateBegin;
                this.getCardDisabled = false;
            }
        },

        getCard: function () {
            if (this.$data.cardType === null) {
                this.$data.alertMessage = 'Пожалуйста, выберите тип карты';
                this.showAlert();
            } else {
                this.getCardDisabled = true;
                var body = {
                    cardProduct: this.$data.cardType.cardProduct,
                    dateBegin: this.$data.cardType.dateBegin
                };
                Companies.post('/get/freecard', body)
                    .then(
                        (response) => {
                            console.log(response);
                            this.pan = response.data.pan
                            this.expDate = response.data.expDate
                            this.embName = response.data.embName
                            this.dateBegin = response.data.dateBegin
                            this.ipContract = response.data.ipContract
                            this.successAlert();
                        },
                        (error) => {
                            console.log(error);
                            this.$data.alertMessage = error.response.data.error.errorMessage;
                            this.showAlert();
                        }
                    )
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },

        createOperation: function () {
            let body = {}
            if (this.$data.operationType === null) {
                this.$data.alertMessage = 'Пожалуйста, выберите тип операции';
                this.showAlert();
                return;
            }
            if (this.$data.companyId === "" || this.$data.companyId == null) {
                this.$data.alertMessage = 'Пожалуйста, заполни id компании';
                this.showAlert();
                return;
            }
            if (this.$data.amount === "" || this.$data.amount == null) {
                this.$data.alertMessage = 'Пожалуйста, заполни сумму операции';
                this.showAlert();
                return;
            }
            this.isProcessing = true;
            body = {
                companyId: this.$data.companyId,
                operationType: this.$data.operationType.code,
                amount: this.$data.amount.replace(",", "."),
                mccCode: this.$data.mccCode,
                merchantName: this.$data.merchantName,
            };
            Companies.post('/cardoperation', body)
                .then(
                    (response) => {
                        console.log(response);
                        this.successAlert();
                    },
                    (error) => {
                        console.log(error);
                        this.$data.alertMessage = error.response.data.error.errorMessage;
                        this.showAlert();
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },

        showAlert() {
            this.isProcessing = false;
            this.getCardDisabled = false;
            this.dismissCountDown = this.dismissSecs;
        },

        successAlert() {
            this.successfullyRequested = true;
            this.getCardDisabled = false;
            this.isProcessing = false;
        }
    },
    computed: {
        maskUUID() {
            const maskUUID =
                /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/i;
            return this.companyId.match(maskUUID) ? true : false;
        },
        maskAmountWithDot() {
            const maskAmountWithDot = /\d{1,}/i;
            return this.amount.match(maskAmountWithDot) ? true : false;
        },
        mccCodeState() {
            const maskMccCode = /[0-9]{4}/i;
            if (this.mccCode.length > 0) { 
                return this.mccCode.match(maskMccCode) ? true : false;
            } else {
                return true;
            }
        },
    }
}
</script>

<style></style>