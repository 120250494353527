<template>
  <div class="workspaceWrapper">
    <div class="workspaceThemeContainer">
      <div id="stage-configuration">
        <div class="form">
          <b-form class="mb-2">
            <h3>Сконфигурировать стейдж</h3>
            <b-row>

              <b-col>
                <h5>Управление генератором</h5>
              </b-col>

              <b-col lg="4" class="mb-2">

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                    v-model="qaToolsState" v-on:click="qaToolsSwitch" :disabled="isProcessing" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Генератор компаний</label>
                </div>

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                    v-model="beneficiariesState" v-on:click="qaToolsBeneficiariesSwitch" :disabled="isProcessing" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Генератор бенефициаров</label>
                </div>

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                    v-model="selfemployedState" v-on:click="qaToolsSelfemployedSwitch" :disabled="isProcessing" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Генератор СМЗ</label>
                </div>

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                    v-model="mocksState" v-on:click="mockSwitch" :disabled="isProcessing" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Моки</label>
                </div>

              </b-col>

            </b-row>

            <b-row>

              <b-col>
                <h5>Состояние пулла компаний</h5>
              </b-col>

              <b-col lg="4" class="mb-2">
                <b-row>
                  <b-button id="show-btn" @click=isShowModalFindByConfiguration()>Выбрать конфигурацию поиска</b-button>
                </b-row>

                <b-modal class="mb-2" v-model="showModalFindByConfiguration" size="lg" hide-footer
                  title="Выбор опций поиска">
                  <b-container fluid>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Тип компании:" label-for="nested-type-company" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-type-company" v-model="formForRequest.companyType"
                            :options="companyType" :aria-describedby="ariaDescribedby" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Карта:" label-for="nested-card" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-card" v-model="formForRequest.withCard" :options="cardOptions"
                            :aria-describedby="ariaDescribedby" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Баланс:" label-for="nested-balance" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-balance" v-model="formForRequest.initialAccountBalance"
                            :options="initialBalanceOptions" :aria-describedby="ariaDescribedby"
                            stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Тариф:" label-for="nested-tariff" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-checkbox-group id="nested-tariff" :state="state" v-model="formForRequest.tariff.options"
                            :options="selectedTariffOptions" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                          <b-form-invalid-feedback :state="state">Пожалуйста, выберите 3 опции</b-form-invalid-feedback>
                          <b-form-invalid-feedback :state="isDuplicate">Список не может содержать одинаковый тип
                            опций</b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Мультикомпания:" label-for="nested-multi-company"
                          v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-form-radio-group id="nested-multi-company" v-model="formForRequest.isMultiCompany"
                            :options="selectedIsMultiCompany" :aria-describedby="ariaDescribedby"
                            stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Доступы сотрудникам:" label-for="nested-employees"
                          v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-form-checkbox-group id="nested-employees"
                            v-model="formForRequest.employeesConfiguration.isExternal"
                            :options="selectedExternalEmployeesConfiguration" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                          <b-form-checkbox-group id="nested-employees"
                            v-model="formForRequest.employeesConfiguration.isInternal"
                            :options="selectedInternalEmployeesConfiguration" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Количество мультикомпаний:" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-input type="text" autocomplete="off" id="nested-count-multi-company"
                            v-model="formForRequest.countMultiCompany" :aria-describedby="ariaDescribedby"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Количество сотрудников:" v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-col><label>Внешние</label></b-col>
                          <b-col><b-form-input type="text" autocomplete="off" id="nested-count-external-employees"
                              v-model="formForRequest.employeesConfiguration.countExternal"
                              :aria-describedby="ariaDescribedby"></b-form-input></b-col>
                          <b-col><label>Внутренние</label></b-col>
                          <b-col><b-form-input type="text" autocomplete="off" id="nested-count-internal-employees"
                              v-model="formForRequest.employeesConfiguration.countInternal"
                              :aria-describedby="ariaDescribedby"></b-form-input></b-col>
                        </b-form-group>
                      </b-col>

                    </b-row>

                  </b-container>

                  <div>
                    <b-button class="mt-3" variant="outline-danger" block
                      @click=isShowModalFindByConfiguration()>Закрыть</b-button>
                    <b-button class="mt-3" type="submit" v-on:click="getCountCompaniesInPoolWithStatus" variant="success"
                      block @click=isShowModalFindByConfiguration()>Выполнить</b-button>
                  </div>

                </b-modal>

                <b-row>
                  <b-button id="show-btn" v-on:click="pullCountCompaniesInPoolWithStatus">Проверить количество компаний
                    на текущих конфигурациях</b-button>
                </b-row>

                <b-row>
                  <b-button id="show-btn" @click=isShowModalAddNewConfiguration()>Добавить конфигурацию</b-button>
                </b-row>

                <b-modal class="mb-2" v-model="showModalAddNewConfiguration" size="lg" hide-footer
                  title="Выбор опций добавления">
                  <b-container fluid>
                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Тип компании:" label-for="nested-type-company" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-type-company" v-model="formForRequest.companyType"
                            :options="companyType" :aria-describedby="ariaDescribedby" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Карта:" label-for="nested-card" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-card" v-model="formForRequest.withCard" :options="cardOptions"
                            :aria-describedby="ariaDescribedby" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row class="mb-2">
                      <b-col>
                        <b-form-group label="Баланс:" label-for="nested-balance" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-radio-group id="nested-balance" v-model="formForRequest.initialAccountBalance"
                            :options="initialBalanceOptions" :aria-describedby="ariaDescribedby"
                            stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Тариф:" label-for="nested-tariff" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-checkbox-group id="nested-tariff" :state="state" v-model="formForRequest.tariff.options"
                            :options="selectedTariffOptions" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                          <b-form-invalid-feedback :state="state">Пожалуйста, выберите 3 опции</b-form-invalid-feedback>
                          <b-form-invalid-feedback :state="isDuplicate">Список не может содержать одинаковый тип
                            опций</b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Мультикомпания:" label-for="nested-multi-company"
                          v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-form-radio-group id="nested-multi-company" v-model="formForRequest.isMultiCompany"
                            :options="selectedIsMultiCompany" :aria-describedby="ariaDescribedby"
                            stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Доступы сотрудникам:" label-for="nested-employees"
                          v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-form-checkbox-group id="nested-employees"
                            v-model="formForRequest.employeesConfiguration.isExternal"
                            :options="selectedExternalEmployeesConfiguration" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                          <b-form-checkbox-group id="nested-employees"
                            v-model="formForRequest.employeesConfiguration.isInternal"
                            :options="selectedInternalEmployeesConfiguration" :aria-describedby="ariaDescribedby"
                            stacked></b-form-checkbox-group>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row class="mb-2">

                      <b-col>
                        <b-form-group label="Количество мультикомпаний:" v-slot="{ ariaDescribedby }"
                          label-align-sm="left">
                          <b-form-input type="text" autocomplete="off" id="nested-count-multi-company"
                            v-model="formForRequest.countMultiCompany" :aria-describedby="ariaDescribedby"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Количество сотрудников:" v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-col><label>Внешние</label></b-col>
                          <b-col><b-form-input type="text" autocomplete="off" id="nested-count-external-employees"
                              v-model="formForRequest.employeesConfiguration.countExternal"
                              :aria-describedby="ariaDescribedby"></b-form-input></b-col>
                          <b-col><label>Внутренние</label></b-col>
                          <b-col><b-form-input type="text" autocomplete="off" id="nested-count-internal-employees"
                              v-model="formForRequest.employeesConfiguration.countInternal"
                              :aria-describedby="ariaDescribedby"></b-form-input></b-col>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="Сколько компаний нужно?" v-slot="{ ariaDescribedby }" label-align-sm="left">
                          <b-col><label>Количество компаний</label></b-col>
                          <b-col>
                            <b-form-input type="text" autocomplete="off" id="nested-needed-count-company"
                              v-model="needenCountCompany"
                              :aria-describedby="ariaDescribedby">
                            </b-form-input>
                          </b-col>
                        </b-form-group>
                      </b-col>

                    </b-row>

                  </b-container>
                  <div>
                    <b-button class="mt-3" variant="outline-danger" block
                      @click=isShowModalAddNewConfiguration()>Закрыть</b-button>
                    <b-button class="mt-3" type="submit" v-on:click="addConfiguration" variant="success"
                      block @click=isShowModalAddNewConfiguration()>Выполнить</b-button>
                  </div>
                </b-modal>

              </b-col>

            </b-row>

          </b-form>

          <b-form class="mb-2">
            <h4>Статус</h4>

            <div>
              <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged">
                {{ alertMessage }}
              </b-alert>
            </div>

            <div>
              <b-alert variant="success" :show="successfullyRequested" dismissible
                @dismissed="showCountCompanyWithStatus = false, successfullyRequested = false">
                Запрос выполнен успешно.
                <hr />
                <div v-if="showCountCompanyWithStatus" id="countCompany">
                  <span v-for="item in showCountCompanyConfigurations" :key="item">
                    <b-row>
                      <b-col>
                        <span>Тип компании: {{ item.companyConfiguration.companyType == 'LEGAL' ? 'ООО' : 'ИП' }}</span>
                        <div class="mt-2"></div>
                        <span>Баланс: {{ item.companyConfiguration.initialAccountBalance }}</span>
                        <div class="mt-2"></div>
                        <span>Карта: {{ item.companyConfiguration.withCard ? 'Есть' : 'Нет' }}</span>
                        <div class="mt-2"></div>
                        <span>Мультикомпания: {{ item.companyConfiguration.isMultiCompany ? 'Да' : 'Нет' }}</span>
                        <div class="mt-2"></div>
                        <span>Количество мультикомпаний: {{ item.companyConfiguration.countMultiCompany }}</span>
                        <div class="mt-2"></div>
                      </b-col>

                      <b-col>
                        <span v-if="item.companyConfiguration.tariff != null">
                          <span>Тариф:</span>
                          <div class="mt-2"></div>
                          <span>{{ item.companyConfiguration.tariff.options[0].packageName }}{{
                            item.companyConfiguration.tariff.options[0].optionType }}</span>
                          <div class="mt-2"></div>
                          <span>{{ item.companyConfiguration.tariff.options[1].packageName }}{{
                            item.companyConfiguration.tariff.options[1].optionType }}</span>
                          <div class="mt-2"></div>
                          <span>{{ item.companyConfiguration.tariff.options[2].packageName }}{{
                            item.companyConfiguration.tariff.options[2].optionType }}</span>
                        </span>
                      </b-col>

                      <b-col>
                        <span>Сотрудники:</span>
                        <div class="mt-2"></div>
                        <span>Внешние: {{ item.companyConfiguration.employeesConfiguration != null ?
                          item.companyConfiguration.employeesConfiguration.countExternal : 0 }}</span>
                        <div class="mt-2"></div>
                        <span>Внутренние: {{ item.companyConfiguration.employeesConfiguration != null ?
                          item.companyConfiguration.employeesConfiguration.countInternal : 0 }}</span>
                        <div class="mt-2"></div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <span>Количество компаний в пуле с конфигурацией: {{ item.counters.find(e => e.companyPoolStatus ==
                        'NEW').companiesCountInPool }}</span>
                    </b-row>

                    <hr align="center" width="300" color="Gray" />

                  </span>
                </div>
              </b-alert>
            </div>

            <div>
              <b-alert :show="isProcessing" dismissible variant="warning">Обработка запроса.</b-alert>
            </div>

          </b-form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Internal } from "@/api/http-common";
export default {
  name: "StageConfiguration",
  data() {
    return {
      id: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      alertMessage: "",
      successfullyRequested: false,
      isProcessing: false,
      qaToolsState: false,
      beneficiariesState: false,
      selfemployedState: false,
      mocksState: false,
      showModalFindByConfiguration: false,
      showModalAddNewConfiguration: false,
      showCountCompanyWithStatus: false,
      configurationsInfo: [],
      showCountCompanyConfigurations: [],
      needenCountCompany: 1,
      formForRequest: {
        companyType: "LEGAL",
        initialAccountBalance: 0,
        withCard: false,
        tariff: {
          options: [],
          promoCode: null
        },
        isMultiCompany: false,
        countMultiCompany: 0,
        employeesConfiguration: {
          countExternal: 0,
          countInternal: 0,
          isExternal: false,
          isInternal: false
        }
      },
      companyType: [
        { text: "ООО", value: "LEGAL" },
        { text: "ИП", value: "INDIVIDUAL" }
      ],
      cardOptions: [
        { text: "С картой", value: true },
        { text: "Без карты", value: false }
      ],
      initialBalanceOptions: [
        { text: "0", value: 0 },
        { text: "1 000", value: 1000 },
        { text: "1 000 000", value: 1000000 },
        { text: "1 500 000", value: 1500000 },
        { text: "2 000 000", value: 2000000 }
      ],
      selectedExternalEmployeesConfiguration: [
        { text: "Внешний сотрудник", value: true }
      ],
      selectedInternalEmployeesConfiguration: [
        { text: "Внутренний сотрудник", value: true }
      ],
      selectedIsMultiCompany: [
        { text: "Нет", value: false },
        { text: "Да", value: true }
      ],
      selectedTariffOptions: [
        // Free tariff
        {
          text: "FreePaymentForLegal", value: {
            packageName: "Free",
            optionType: "PaymentForLegal"
          }
        },
        {
          text: "FreePaymentForIndividual", value: {
            packageName: "Free",
            optionType: "PaymentForIndividual"
          }
        },
        {
          text: "FreeATM", value: {
            packageName: "Free",
            optionType: "ATM"
          }
        },

        // Base tariff
        {
          text: "BasePaymentForLegal", value: {
            packageName: "Base",
            optionType: "PaymentForLegal"
          }
        },
        {
          text: "BasePaymentForIndividual", value: {
            packageName: "Base",
            optionType: "PaymentForIndividual"
          }
        },
        {
          text: "BaseATM", value: {
            packageName: "Base",
            optionType: "ATM",
          }
        },

        //Max tariff
        {
          text: "MaxPaymentForLegal", value: {
            packageName: "Max",
            optionType: "PaymentForLegal"
          }
        },
        {
          text: "MaxPaymentForIndividual", value: {
            packageName: "Max",
            optionType: "PaymentForIndividual"
          }
        },
        {
          text: "MaxATM", value: {
            packageName: "Max",
            optionType: "ATM"
          }
        }
      ]
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.checkQaToolsState()
        this.checkMockState()
        this.checkBeneficiariesState()
        this.checkSelfemployedState()
        this.getCurrentCompaniesConfigurations()
      },
      { immediate: true }
    )
  },
  computed: {
    state() {
      return this.formForRequest.tariff.options.length === 3 || this.formForRequest.tariff.options.length === 0
    },
    isDuplicate() {
      return (this.formForRequest.tariff.options.filter(el => el.optionType == "ATM").length === 1) &&
        (this.formForRequest.tariff.options.filter(el => el.optionType == "PaymentForLegal").length === 1) &&
        (this.formForRequest.tariff.options.filter(el => el.optionType == "PaymentForIndividual").length === 1) ||
        (this.formForRequest.tariff.options.length === 0)
    }
  },
  methods: {
    addConfiguration: function() {
      this.isProcessing = true
      var defaultTariff = {
        options: [
          { packageName: "Free", optionType: "PaymentForLegal" },
          { packageName: "Free", optionType: "PaymentForIndividual" },
          { packageName: "Free", optionType: "ATM" }
        ],
        promoCode: null
      }

      var body = {
        companiesCount: this.$data.needenCountCompany,
        companyConfiguration: {
          companyType: this.formForRequest.companyType,
          initialAccountBalance: this.formForRequest.initialAccountBalance,
          withCard: this.formForRequest.withCard,
          tariff: this.formForRequest.tariff.options.length == 0 ? defaultTariff : this.formForRequest.tariff,
          isMultiCompany: this.formForRequest.isMultiCompany,
          countMultiCompany: this.formForRequest.countMultiCompany,
          employeesConfiguration: this.formForRequest.employeesConfiguration
        }
      }

      Internal.post(`/set/configuration`, body)
      .then(
        (response) => {
          console.log(response);
          this.successAlert();
        },
        (error) => {
          console.log(error);
          this.$data.alertMessage = error.response.data.error.errorMessage;
          this.showAlert();
        }
      )
      .catch((e) => {
        console.log(e);
      });
    },
    getCountCompaniesForCurrentConfigurations: function () {
      this.isProcessing = true
      this.showCountCompanyConfigurations = [];

      this.configurationsInfo.forEach((value) => {
        Internal.post(`/poolstate`, value.companyConfiguration)
          .then(
            (response) => {
              console.log(response);
              this.$data.showCountCompanyConfigurations.push(response.data.companiesInfo);
              this.showCountCompanyWithStatus = true
              this.successAlert();
            },
            (error) => {
              console.log(error);
              this.$data.alertMessage = error.response.data.error.errorMessage;
              this.showAlert();
            }
          )
          .catch((e) => {
            console.log(e);
          });
      })
    },
    getCurrentCompaniesConfigurations: async function () {
      await Internal.get(`/poolconfiguration`)
        .then(
          (response) => {
            console.log(response);
            this.$data.configurationsInfo = response.data.configuration
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    getCountCompaniesInPoolWithStatus: function () {
      this.isProcessing = true
      this.showCountCompanyConfigurations = [];

      var body = {
        companyType: this.formForRequest.companyType,
        initialAccountBalance: this.formForRequest.initialAccountBalance,
        withCard: this.formForRequest.withCard,
        tariff: this.formForRequest.tariff.options.length == 0 ? null : this.formForRequest.tariff,
        isMultiCompany: this.formForRequest.isMultiCompany,
        countMultiCompany: this.formForRequest.countMultiCompany,
        employeesConfiguration: this.formForRequest.employeesConfiguration
      }
      Internal.post(`/poolstate/NEW`, body)
        .then(
          (response) => {
            console.log(response);
            this.$data.showCountCompanyConfigurations.push(response.data.companiesInfo)
            this.showCountCompanyWithStatus = true
            this.successAlert();
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    pullCountCompaniesInPoolWithStatus: function () {
      this.successfullyRequested = false
      this.showCountCompanyWithStatus = false
      this.showCountCompanyConfigurations = [];
      this.getCountCompaniesForCurrentConfigurations()
    },
    checkBeneficiariesState: function () {
      Internal.get(`/beneficiariescreator/state`)
        .then(
          (response) => {
            console.log(response);
            this.$data.beneficiariesState = response.data.beneficiariesCreatorIsEnabled
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    qaToolsBeneficiariesSwitch: function () {
      this.isProcessing = true;
      var body = {
        isEnabled: this.$data.beneficiariesState ? false : true
      }
      Internal.put(`/beneficiariescreator/state`, body)
        .then(
          (response) => {
            console.log(response);
            this.$data.alertMessage = "Генератор переключен бенефициаров " + body.isEnabled;
            this.successAlert();
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    checkSelfemployedState: function () {
      Internal.get(`/selfemployedcreator/state`)
        .then(
          (response) => {
            console.log(response);
            this.$data.selfemployedState = response.data.selfEmployedCreatorIsEnabled
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    qaToolsSelfemployedSwitch: function () {
      this.isProcessing = true;
      var body = {
        isEnabled: this.$data.selfemployedState ? false : true
      }
      Internal.put(`/selfemployedcreator/state`, body)
        .then(
          (response) => {
            console.log(response);
            this.$data.alertMessage = "Генератор переключен самозанятых " + body.isEnabled;
            this.successAlert();
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    checkQaToolsState: function () {
      Internal.get(`/companiescreator/state`)
        .then(
          (response) => {
            console.log(response);
            this.$data.qaToolsState = response.data.companiesCreatorIsEnabled
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    checkMockState: function () {
      Internal.get(`/staging/stagingsettings`)
        .then(
          (response) => {
            console.log(response);
            this.$data.mocksState = response.data.resultOfChecking ? false : true
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    qaToolsSwitch: function () {
      this.isProcessing = true;
      var body = {
        isEnabled: this.$data.qaToolsState ? false : true
      }
      Internal.put(`/companiescreator/state`, body)
        .then(
          (response) => {
            console.log(response);
            this.$data.alertMessage = "Генератор переключен " + body.isEnabled;
            this.successAlert();
          },
          (error) => {
            console.log(error);
            this.$data.alertMessage = error.response.data.error.errorMessage;
            this.showAlert();
          }
        )
        .catch((e) => {
          console.log(e)
        });
    },
    mockSwitch: function () {
      this.isProcessing = true;
      var thumbler = this.$data.mocksState ? false : true
      if (thumbler) {
        Internal.put(`/staging/autotestingmode`)
          .then(
            (response) => {
              console.log(response);
              this.$data.alertMessage = "Моки включены";
              this.successAlert();
            },
            (error) => {
              console.log(error);
              this.$data.alertMessage = error.response.data.error.errorMessage;
              this.showAlert();
            }
          )
          .catch((e) => {
            console.log(e);
          });
      } else {
        Internal.put(`/staging/thirdpartyservicesmode`)
          .then(
            (response) => {
              console.log(response);
              this.$data.alertMessage = "Моки выключены";
              this.successAlert();
            },
            (error) => {
              console.log(error);
              this.$data.alertMessage = error.response.data.error.errorMessage;
              this.showAlert();
            }
          )
          .catch((e) => {
            console.log(e);
          });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isShowModalFindByConfiguration() {
      this.showModalFindByConfiguration = !this.showModalFindByConfiguration
      if (this.showModalFindByConfiguration) {
        this.showCountCompanyWithStatus = false
        this.successfullyRequested = false
      }
    },
    isShowModalAddNewConfiguration() {
      this.showModalAddNewConfiguration = !this.showModalAddNewConfiguration
      if (this.showModalAddNewConfiguration) {
        this.showCountCompanyWithStatus = false
        this.successfullyRequested = false
      }
    },
    showAlert() {
      this.isProcessing = false;
      this.dismissCountDown = this.dismissSecs;
    },
    successAlert() {
      this.id = "";
      this.successfullyRequested = true;
      this.isProcessing = false;
    },
  },
};
</script>

<style></style>
