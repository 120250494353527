<template>
  <div class="workspaceWrapper">
    <div class="workspaceThemeContainer">
      <div id="find-company">
        <b-form class="mb-2">
          <h3>Найти компанию</h3>
          <div>
            <div class="mt-2"></div>
            <b-form-input type="text" autocomplete="off" placeholder="Начальный баланс" id="balance" v-model="balance"
              :state="maskAmount" />
            <b-form-invalid-feedback id="amount-feedback">
              Заполни поле баланса
            </b-form-invalid-feedback>
            <div class="mt-2"></div>
          </div>

          <table>
            <tr>
              <th>
                <b-form-group label="Тип компании" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="type-company-options" v-model="selectedCompanyTypeOption"
                    :options="companyOptions" name="company-radio-options" :aria-describedby="ariaDescribedby">
                  </b-form-radio-group>
                  <div class="mt-2"></div>
                </b-form-group>
              </th>
              <th>
                <b-form-group label="Карта" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="card-options" v-model="selectedCardOption" :options="cardOptions"
                    name="card-radio-card-options" :aria-describedby="ariaDescribedby">
                  </b-form-radio-group>
                  <div class="mt-2"></div>
                </b-form-group>
              </th>
            </tr>
          </table>

          <b-button v-on:click="findCompany" :disabled="isProcessing" type="submit" variant="primary">Выполнить</b-button>
        </b-form>
        <b-form class="mb-2">
          <h3>Статус</h3>
          <div>
            <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged">
              {{ alertMessage }}
            </b-alert>
          </div>
          <div>
            <b-alert variant="success" :show="successfullyRequested" dismissible>
              Запрос выполнен успешно.
              <hr />
              <span id="companyName">Наименование: {{ companyName }}</span>
              <div class="mt-2"></div>
              <span id="phoneNumber">Номер телефона: {{ phoneNumber }}</span>
              <div class="mt-2"></div>
              <span id="phoneNumber">Пароль: {{ userPassword }}</span>
              <div class="mt-2"></div>
              <span id="companyInn">ИНН: {{ companyInn }}</span>
              <div class="mt-2"></div>
              <span id="companyOGRN">ОГРН: {{ companyOGRN }}</span>
              <div class="mt-2"></div>
              <span id="companyAccountNumber">Счет: {{ companyAccountNumber }}</span>
            </b-alert>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Companies } from "@/api/http-common";

export default {
  name: "FindCompany",
  data() {
    return {
      balance: "0",
      typeCompany: false,
      typeIndividual: true,
      isProcessing: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: "",
      successfullyRequested: false,
      selectedCardOption: false,
      selectedCompanyTypeOption: "INDIVIDUAL",
      companyOptions: [
        { text: "ООО", value: "LEGAL" },
        { text: "ИП", value: "INDIVIDUAL" }
      ],
      cardOptions: [
        { text: "С картой", value: true },
        { text: "Без карты", value: false },
      ],
      phoneNumber: "",
      companyInn: "",
      companyAccountNumber: "",
      companyOGRN: "",
      companyName: "",
      userPassword: ""
    };
  },
  methods: {
    selectedCompanyType: function () {
      this.typeIndividual = false;
      this.typeCompany = true;
    },
    selectedIndividualType: function () {
      this.typeCompany = false;
      this.typeIndividual = true;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.isProcessing = false;
      this.dismissCountDown = this.dismissSecs;
    },
    successAlert() {
      this.balance = "";
      this.successfullyRequested = true;
      this.isProcessing = false;
    },
    storeHistory() {
      this.$store.commit('addHistory', {
        name: this.companyName,
        phone: this.phoneNumber,
        password: this.userPassword,
        inn: this.companyInn,
        ogrn: this.companyOGRN,
        account: this.companyAccountNumber,
      });
    },
    findCompany: function () {
      if (this.$data.balance === "" || this.$data.balance === null) {
        this.$data.alertMessage = 'Пожалуйста, заполни поле "balance"';
        this.showAlert();
      } else {
        this.isProcessing = true;
        var body = {
          companyConfiguration: {
            companyType: this.$data.selectedCompanyTypeOption,
            initialAccountBalance: this.$data.balance,
            withCard: this.$data.selectedCardOption,
            isMultiCompany: false,
            countMultiCompany: 0,
            tariff: null,
          },
          companiesCount: 1,
          mockExpectationsTtl: null,
        };
        Companies.post(`/get`, body)
          .then(
            (response) => {
              console.log(response);
              this.phoneNumber = response.data.companiesInfo.companiesSpecs[0].employees[0].baseInfo.phone
              this.companyInn = response.data.companiesInfo.companies[0].inn
              this.companyAccountNumber = response.data.companiesInfo.companies[0].bankAccounts[0].number
              this.companyOGRN = response.data.companiesInfo.companies[0].ogrn
              this.companyName = response.data.companiesInfo.companies[0].name
              this.userPassword = response.data.companiesInfo.companiesSpecs[0].employees[0].password
              this.successAlert();
              this.storeHistory();
            },
            (error) => {
              console.log(error);
              this.$data.alertMessage = error.response.data.error.errorMessage;
              this.showAlert();
            }
          )
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  computed: {
    maskAmount() {
      const maskAmount = /\d{1,}/i;
      return this.balance.match(maskAmount) ? true : false;
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  /* Ширина таблицы */
  border-spacing: 3px;
  /* Расстояние между ячейками */
}

td,
th {
  padding: 5px;
  /* Поля вокруг текста */
}

label span {
  display: inline-block;
  margin-left: 10px;
}
</style>