<template>
    <div id="card-operation">
        <h1>Сэмулировать операцию по карте для компании</h1>
        <form class="d-flex flex-column">
            <div class="field field--secondary order-first sm:w-400 w-100p">
                <input
                    formControlName="id"
                    type="text"
                    inputmode="numeric"
                    mask="00000000-0000-0000-0000-000000000000"
                    autocomplete="off"
                    class="field__control"
                />
                <span class="field__title">
                    id компании
                </span>
            </div>

            <button
                b-shared-button
                type="submit"
                class="btn btn--primary btn--lg sm:w-260 w-100p lg:mt-40 mt-32"
            >
                Выполнить
            </button>
        </form>
    </div>
</template>

<script>
export default {
    name: "CardOperation"
}
</script>

<style>

</style>
