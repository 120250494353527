import axios from 'axios';

export const Companies = axios.create({
    baseURL: `/companies`
});

export const Internal = axios.create({
    baseURL: `/internal`
});

export const Actuator = axios.create({
    baseURL: `/actuator`
});
